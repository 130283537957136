import React from 'react'
import styled from 'styled-components';

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import SliderItem from '../../atoms/SliderItem';

import './style.css'

SwiperCore.use([Navigation, Pagination, Autoplay]);

const StyledContainer = styled.div`
    background-image: url(${props => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 4rem 0 0;
`;

const StyledText = styled.p`
    text-align: center;
    margin: 0 auto;
    padding: 10% 32px 0;
`;

const StyledHighlight = styled.span`
    color: #fff;
    font-family: "BodoniFLF-Bold";
    font-size: 15rem;
    font-weight: 200;
    line-height: 9rem;
    margin: 0;
`;

const StyledDescription = styled.span`
    color: #fff;
    font-family: 'DIN Next LT Pro Light';
    font-size: 2.2rem;
    font-weight: 200;
    line-height: 2.5rem;
    text-align: center;
    margin: 0;
`;

const Slider = ({data}) => {
    const slides = data.map((item, key) => {
        return(
            <SwiperSlide key={key}>
                <SliderItem>
                    <StyledContainer background={item.image}>
                        <StyledText>
                            <StyledHighlight>{item.highlight}</StyledHighlight>
                            <StyledDescription>{item.description}</StyledDescription>
                        </StyledText>
                    </StyledContainer>
                </SliderItem>
            </SwiperSlide>
        )
    });

    return (
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        
        navigation
        loop
        setWrapperSize={true}
        pagination={{ clickable: true }}
      >
        {slides}
      </Swiper>
    );
}

export default Slider