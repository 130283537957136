import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components';

import Header from "../organisms/Header"

const StyledContainer = styled.div`
    width: 100vw;
    height: 100vh;
`;

const Landingpage = ({children}) => {
    return (
        <StyledContainer>
            <Header />
            <main>
                {children}
            </main>
        </StyledContainer>
    )
}

Landingpage.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Landingpage