import React from "react"
import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 100vw;
    height: 20px;
`;

const SliderItem = ({ color, children, ...props }) => (
    <StyledContainer color={color} {...props}>
        {children}
    </StyledContainer>
)

export default SliderItem