import * as React from "react"

import Landingpage from "../components/templates/Landingpage"
import Seo from '../components/atoms/Seo'
import Slider from "../components/molecules/Slider"

import CompanyImage from '../images/landingpage/company.jpg'
import MapImage from '../images/landingpage/map.jpg'
import CityImage from '../images/landingpage/city-night.jpg'

// markup
const IndexPage = () => {
  const SliderData = [
      {
        image: CompanyImage,
        highlight: 'i',
        description: 'nnovative approach to the delivery of legal services'
      },
      {
        image: MapImage,
        highlight: 'c',
        description: 'ustomized to meet our clients evolving needs at each stage of growth'
      },
      {
        image: CityImage,
        highlight: 'o',
        description: 'ver $1 billion in financings venture capital and M&A'
      },
      {
        image: CompanyImage,
        highlight: 'a',
        description: 'ttorneys with extensive business experience'
      },
      {
        image: MapImage,
        highlight: 'r',
        description: 'elevant real world counsel to our clients'
      },
  ];

  return (
    <Landingpage>
      <Seo
      title='Pedley Law PLLC'
      description='Pedley Law PLLC is a law firm providing legal, business, and strategic counsel primarily to start-up and emerging growth companies and individuals.'
      />
      
      <Slider data={SliderData} />
    </Landingpage>
  )
}

export default IndexPage
